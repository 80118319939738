<template>
  <div uk-lightbox="animation: slide" class="cardWrap">
    <component :is="card.images[0]?'a':'div'" :href="getImgPath(card.images[0])" class="card" :data-caption="card.title">
      <div v-if="card.images[0]" class="card__imgWrap" :src="getImgPath(card.images[0])">
        <img v-for="(item, index) in card.images" :key="index" class="card__img" :src="getImgPath(item)" alt="" />
      </div>
      <div class="card__content">
        <h4 class="card__content-title">{{ card.title }}</h4>
        <ul class="card__content-description">
          <li v-for="(item, index) in card.description" :key="index" v-html="item"></li>
        </ul>
      </div>
    </component>
    <template v-for="(item, index) in card.images" :key="index">
      <a v-if="index" :href="getImgPath(item)" :data-caption="card.title" class="hidden"></a>
    </template>
    <button @click="(event) => copyToClipboard(card.title, card.description, event)" class="card__copy">
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect fill="none" stroke="#FDB128" x="3.5" y="2.5" width="12" height="16"></rect><polyline fill="none" stroke="#FDB128" points="5 0.5 17.5 0.5 17.5 17"></polyline></svg>
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#FDB128" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    card: {
      type: Object,
      required: true,
    }
  },
  methods: {
    getImgPath(image) {
      try {
        return require(`@/content/${this.$parent.environment}/${image}`);
      } catch (e) {
        return '';
      }
    },

    getShortcode(path, params) {
      let code = `{{< \r\n${path.replace('.html', '')}`;

      for (let item of params) {
        let row = item.split(/\s+/).filter(element => {
              return element !== '';
            }),
            firstRowEl = ( row.length ) ? row[0] : '';

        row = row.join('');

        if ( !row.length || row.includes('params') ) {
          continue;
        } else if ( row.includes('---') || row.includes('===') || row.includes('jsonfile') ) {
          break;
        } else {
          code += `\r\n${firstRowEl}=""`;
        }
      }

      code += '\r\n>}}'

      return code;
    },

    copyToClipboard(path, params, event) {
      const el = document.createElement('textarea');

      el.value = this.getShortcode(path, params);
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);

      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      event.target.classList.add('copied');

      setTimeout(function (){
        event.target.classList.remove('copied');
      }, 1000)

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    }
  }
}
</script>

<style scoped>
  .cardWrap {
    @apply relative;
  }
  .card {
    @apply transition-shadow duration-300 ease-in-out col-span-12 sm:col-span-6 md:col-span-5 xl:col-span-4 block border rounded border-solid border-light-gray shadow-2 hover:shadow-2-hover bg-white;
  }

  .card__copy {
    @apply transition-opacity duration-300 ease-in-out opacity-0 absolute top-2 right-2 p-2 rounded border border-solid border-white shadow-2 hover:shadow-2-hover bg-white;
  }
  .cardWrap:hover .card__copy {
    @apply opacity-100;
  }
  .card__copy svg {
   pointer-events: none;
  }
  .card__copy svg:last-child,
  .card__copy.copied svg:first-child {
    @apply hidden;
  }
  .card__copy.copied svg:last-child {
    @apply block;
  }

  .card__content {
    @apply relative px-8 pt-10 pb-8;
  }
  .card__content-title {
    @apply font-red-had font-bold text-lg leading-normal tracking-01 text-black mb-4 break-all;
  }
  .card__content-description {
    @apply whitespace-pre-wrap;
  }

  .card__img:not(:first-child){
    display: none;
  }
</style>
