import { createRouter, createWebHistory } from "vue-router"
import Home from "@/components/pages/Home";
import Constructor from "@/components/pages/Constructor";
import NotFound from "@/components/pages/NotFound";
const routeInfos = [
    { path: '/', component: Home },
    { path: '/constructor', component: Constructor },
    { path: '/:pathMatch(.*)*', component: NotFound }
]

const router = createRouter({
    history : createWebHistory(),
    routes : routeInfos
})

export default router;