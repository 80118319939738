import { createApp } from 'vue'
import router from "./router"
import App from './App.vue'
import './assets/libs/tailwind.css'
import './assets/libs/uikit.css'
import UIkit from './assets/libs/uikit.min.js'

window.UIkit = UIkit

createApp(App).use(router).mount('#app')
