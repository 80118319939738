<template>
    <div class="cards">
        <div class="container">
            <div v-if="cards.length" class="cards__list uk-child-width-1-2@s uk-child-width-1-3@m" uk-grid="masonry: true">
                <card-item v-for="card in cards" :card="card" :key="card.title" />
            </div>

            <div v-else class="uk-alert-danger" uk-alert>
              <p>Nothing found</p>
            </div>
        </div>
    </div>
</template>

<script>
    import CardItem from '@/components/loop/CardItem.vue'

    export default {
        name: "CardList",
        components: {
            CardItem
        },
        props: {
            cards: {
                type: Array,
                required: true
            },
            environment: {
                type: String
            }
        }
    }
</script>

<style scoped>
</style>