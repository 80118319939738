<template>
  <div class="page404">
    <div class="container">
      <div class="page404__imgWrap">
        <img class="page404__img" src="@/assets/img/page404.svg" alt="" >
      </div>
      <h1 class="page404__title">
        Looks like i caught <br>the wrong page
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PathNotFound"
}
</script>

<style scoped>
  .page404 {
    @apply text-center text-dark-gray;
  }

  .page404__img {
    @apply block max-w-full w-9/12 mx-auto;
  }

  .page404__title {
    @apply font-red-had;
    @apply font-bold;
    @apply text-2xl md:text-3xl xl:text-5xl;
    @apply leading-tight;
    @apply mt-6;
  }
</style>