<template>
  <Header></Header>

  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import Header from '@/components/base/Header.vue'

export default {
  name: "App",
  components: {
    Header
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

html{
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply font-maven-pro font-normal text-base leading-normal text-dark-gray;
}

main {
  @apply py-16 lg:py-20;
}
</style>
