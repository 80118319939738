<template>

  <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">
      <button class="uk-offcanvas-close" type="button" uk-close></button>

      <div class="uk-margin">
        <form class="">
          <div class="uk-margin">
          </div>

          <div class="uk-nav uk-nav-default">
            <ul class="uk-nav uk-nav-default">
              <li class="uk-nav-header">Mode</li>
              <li>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <label><input class="uk-radio" type="radio" v-model="enableSlides" value=""> Image</label>
                  <label><input class="uk-radio" type="radio" v-model="enableSlides" value="1"> Slided</label>
                </div>
              </li>

              <li class="uk-nav-header">Header and Footer</li>
              <li>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <label><input class="uk-radio" type="radio" v-model="enableHeaderFooter" value="1"> On</label>
                  <label><input class="uk-radio" type="radio" v-model="enableHeaderFooter" value=""> Off</label>
                </div>
              </li>

              <li class="uk-nav-header">Environment</li>
              <li>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <label><input class="uk-radio" type="radio" @input="getEnvironment($event.target.value)" v-model="environment" value="stable"> Production</label>
                  <label><input class="uk-radio" type="radio" @input="getEnvironment($event.target.value)" v-model="environment" value="staging"> Staging</label>
                  <label><input class="uk-radio" type="radio" @input="getEnvironment($event.target.value)" v-model="environment" value="preview"> Preview</label>
                </div>
              </li>

              <li class="uk-nav-header" v-if="cards.length">Select shortcodes</li>
              <li v-if="cards.length">
                <div class="uk-margin flex flex-col">
                  <template v-for="(card, index) in cards" :key="index">
                    <label v-if="card.images[0]" class="" :for="'shortcode_' + index"><input :id="'shortcode_' + index"  class="uk-checkbox" type="checkbox" :value="index" v-model="checked.shortcodes"> {{ card.title }}</label>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>

    </div>
  </div>

  <div class="constructor">
    <div class="container">
      <div v-if="cardsResult.length" class="grid grid-cols-3 gap-4">
        <div class="col-span-3 md:col-span-1 self-start sticky top-2">
          <div v-if="cardsResult.length" class="mb-4 flex">
            <button @click="generatePage()" class="constructor__btn" type="button" uk-toggle="target: #modal-generate-page">Generate page</button>
            <button @click="sharePage()" class="constructor__btn ml-auto" type="button" uk-toggle="target: #modal-share-page">Share</button>

            <div id="modal-generate-page" uk-modal>
              <div class="uk-modal-dialog uk-modal-body">
                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-alert-success" style="margin-bottom: 0!important;" uk-alert>Copied to clipboard</div>
                <div class="whitespace-pre-wrap">{{ generatedPageCode }}</div>
              </div>
            </div>

            <div id="modal-share-page" uk-modal>
              <div class="uk-modal-dialog uk-modal-body">
                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-alert-success" style="margin-bottom: 0!important;" uk-alert>Copied to clipboard</div>
              </div>
            </div>
          </div>

          <div class="p-4 block border rounded border-solid border-light-gray shadow-2 bg-white">
            <div id="sortable" class="constructor__sort" uk-sortable>
              <div :data-index="( sharedMenuSort.length ) ? sharedMenuSort[index] : index" class="constructor__sort-item border-b" v-for="(card, index) in sortableList" :key="index">
                <div v-if="card.images[0]" class="flex items-center p-2">
                  <span><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="3" width="18" height="1"></rect><rect x="1" y="7" width="18" height="1"></rect><rect x="1" y="11" width="18" height="1"></rect><rect x="1" y="15" width="18" height="1"></rect></svg></span>
                  <span class="ml-2">{{ card.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-3 md:col-span-2 p-4 block border rounded border-solid border-light-gray shadow-2 bg-white">
          <div v-if="enableHeaderFooter">
            <img :src="getImgPath('header.jpg', 'assets/img')" />
          </div>

          <div v-for="(card, index) in cardsResult" :key="index">
              <div v-if="enableSlides" class="sliderWrap">
                <img v-if="card.images[0]" :src="getImgPath(card.images[0])" class="sliderAdditionalImg" />
                <div v-if="card.images.length" class="shortcodeSlider uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow>
                  <ul class="uk-slideshow-items">
                    <li v-for="(image, index) in card.images" :key="index">
                      <img :src="getImgPath(image)" />
                    </li>
                  </ul>

                  <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                  <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
                </div>
              </div>
              <div v-else class="">
                <img v-if="card.images[0]" :src="getImgPath(card.images[0])" />
              </div>
          </div>

          <div v-if="enableHeaderFooter">
            <img :src="getImgPath('footer.jpg', 'assets/img')" />
          </div>
        </div>
      </div>

      <div v-else class="uk-alert-danger" uk-alert>
        <p>Nothing selected</p>
      </div>
    </div>
  </div>
</template>

<script>
import cardsData from '@/content/stable/db.json'

export default {
  name: "Constructor",
  data(){
    return {
      enableHeaderFooter: 1,
      enableSlides: '',
      cards: cardsData,
      sorting: [],
      shared: false,
      sharedMenuSort: [],
      generatedPageCode: '',
      environment: 'stable',
      checked: {
        shortcodes: []
      }
    }
  },
  mounted() {
    let query = this.$route.query;

    if ( Object.keys(query).length ) {
      if ( query.environment ) {
        this.environment = query.environment;
      }

      if ( query.slider ) {
        this.enableSlides = query.slider;
      }

      if ( query.hf ) {
        this.enableHeaderFooter = query.hf;
      }

      if ( query.shortcodes ) {
        this.checked.shortcodes = query.shortcodes.split(',');
      }

      if ( query.sorting ) {
        this.sorting = query.sorting.split(',');
        this.shared = true;
        this.sharedMenuSort = this.sorting;
      }
    }
  },
  watch: {
    cardsResult() {
      if ( this.sharedMenuSort.length != this.cardsResult.length ) {
        this.sharedMenuSort = [];
      }
    }
  },
  updated() {
    let _this = this;

    UIkit.util.on('#sortable', 'moved', function () {
      this.shared = false;
      _this.getSorting();
    });

    UIkit.util.on('.shortcodeSlider', 'itemshow', function (el) {
      let slide = el.target,
          activeImgUrl = slide.querySelector('img').getAttribute('src');

          slide.parentNode.parentNode.previousSibling.setAttribute('src', activeImgUrl);
    });
  },
  methods: {
    getImgPath(image, hasPath = '') {
      try {
        if ( hasPath ) {
          return require(`@/${hasPath}/${image}`);
        } else {
          return require(`@/content/${this.environment}/${image}`);
        }
      } catch (e) {
        return '';
      }
    },

    getEnvironment: function(val) {
      this.environment = val;

      this.addCards();
    },

    addCards() {
      try {
        this.cards = require(`@/content/${this.environment}/db.json`);
      } catch (e) {
        this.cards = []
      }
    },

    getSorting() {
      let elements = document.querySelectorAll( '#sortable .constructor__sort-item' ),
          tempArr = [];

      elements.forEach(function (el){
        tempArr.push(el.dataset.index);
      });

      this.sorting = tempArr;
    },

    copyToClipboard(text){
      const el = document.createElement('textarea');

      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);

      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },

    generatePage(){
      let cards = this.cardsResult,
          code = '',
          _this = this;

      cards.forEach(function (card){
        code += _this.getShortcode(card.title, card.description);
      })

      this.generatedPageCode = code;

      this.copyToClipboard(code);
    },

    sharePage() {
      let shareUrl = 'http://cl-sd.vegys.in.ua/constructor'; //todo

      shareUrl += '?environment=' + this.environment;

      if ( this.enableSlides ) {
        shareUrl += '&slider=' + this.enableSlides;
      }

      if ( this.enableHeaderFooter ) {
        shareUrl += '&hf=' + this.enableHeaderFooter;
      }

      if ( this.checked.shortcodes ) {
        shareUrl += '&shortcodes=' + this.checked.shortcodes.toString();
      }

      if ( this.sorting ) {
        shareUrl += '&sorting=' + this.sorting.toString();
      }

      this.copyToClipboard(shareUrl);
    },

    getShortcode(path, params) {
      let code = `\r\n{{< \r\n${path.replace('.html', '')}`;

      for (let item of params) {
        let row = item.split(/\s+/).filter(element => {
              return element !== '';
            }),
            firstRowEl = ( row.length ) ? row[0] : '';

        row = row.join('');

        if ( !row.length || row.includes('params') ) {
          continue;
        } else if ( row.includes('---') || row.includes('===') || row.includes('jsonfile') ) {
          break;
        } else {
          code += `\r\n${firstRowEl}=""`;
        }
      }

      code += '\r\n>}}\r\n'

      return code;
    }
  },
  computed: {
    sortableList: function() {
      let shortcodes = this.checked.shortcodes,
          shared = this.shared,
          sorting = this.sharedMenuSort,
          results = [],
          intersect = function(arr, find) {
            return arr.filter(function(n) {
              return n == find;
            });
          };

      if (shortcodes.length) {
        results = this.cards.filter((card, index) => intersect(shortcodes, index).length);
      }

      if ( shared && sorting.length ) {
        let temp = results;

        results.sort((a,b) => sorting.indexOf(temp.indexOf(a).toString()) - sorting.indexOf(temp.indexOf(b).toString()));
      }

      return results;
    },
    cardsResult: function() {
      let shortcodes = this.checked.shortcodes,
          sorting = this.sorting,
          results = [],
          intersect = function(arr, find) {
            return arr.filter(function(n) {
              return n == find;
            });
          };

      if (shortcodes.length) {
        results = this.cards.filter((card, index) => intersect(shortcodes, index).length);

        if ( sorting.length ) {
          let temp = results;

          results.sort((a,b) => sorting.indexOf(temp.indexOf(a).toString()) - sorting.indexOf(temp.indexOf(b).toString()));
        }
      }

      return results;
    }
  }
}
</script>

<style scoped>
.uk-slidenav {
  @apply bg-brand-orange hover:bg-ghost-links;
}

.constructor__btn {
  @apply bg-brand-orange font-red-had font-bold text-base text-white px-6 py-2 rounded transition-all hover:bg-ghost-links;
}

.constructor__sort-item:last-child {
  border-bottom: none !important;
}

.sliderWrap {
  position: relative;
}
.sliderWrap .uk-slideshow {
  position: absolute !important;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.sliderWrap .uk-slideshow-items {
  min-height: auto !important;
  height: 100%;
}
.sliderAdditionalImg {
  opacity: 0;
}
</style>