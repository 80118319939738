<template>
  <card-list :environment="environment" :cards="cardsDisplayed" />

  <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">
      <button class="uk-offcanvas-close" type="button" uk-close></button>

      <div class="uk-margin">
        <form class="">
          <div class="uk-margin">
          </div>

          <div class="uk-nav uk-nav-default">
            <ul class="uk-nav uk-nav-default">
              <li class="uk-nav-header">Environment</li>
              <li>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <label><input class="uk-radio" type="radio" @input="getEnvironment($event.target.value)" v-model="environment" value="stable"> Production</label>
                  <label><input class="uk-radio" type="radio" @input="getEnvironment($event.target.value)" v-model="environment" value="staging"> Staging</label>
                  <label><input class="uk-radio" type="radio" @input="getEnvironment($event.target.value)" v-model="environment" value="preview"> Preview</label>
                </div>
              </li>

              <li v-if="cards.length">
                <div class="uk-search uk-search-default" style="width: 100%;">
                  <span uk-search-icon></span>
                  <input @input="searching($event.target.value)"
                         class="uk-search-input"
                         type="search"
                         placeholder="Search">
                </div>
              </li>

              <li class="uk-nav-header" v-if="cards.length">Sort</li>
              <li v-if="cards.length">
                <div class="uk-margin">
                  <select v-model="productOrder" class="uk-select">
                    <option value="" selected disabled>Select</option>
                    <option value="desc">Newest</option>
                    <option value="asc">Oldest</option>
                  </select>
                </div>
              </li>

              <li class="uk-nav-header" v-if="cards.length">Partition</li>
              <li v-if="cards.length">
                <div class="uk-margin flex flex-col">
                  <template v-for="option in partition" :key="option">
                    <label class="capitalize" :for="option"><input :id="option"  class="uk-checkbox" type="checkbox" :value="option" v-model="checked.partition"> {{ option }}</label>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import CardList from '@/components/loop/CardList.vue'
//import cardsData from '@/content/stable/db.json'

export default {
  name: 'Home',
  components: {
    CardList
  },
  data(){
    return {
      search: '',
      environment: 'stable',
      cards: [],
      currentPage: 1,
      cardsPerPage: 9,
      productOrder: '',
      checked: {
        partition: []
      }
    }
  },
  methods: {
    available: function(category) {
      const categorySet = new Set([]);
      for (var i = 0; i < this.cards.length; i++) {
        this.cards[i][category].forEach(el => categorySet.add(el));
      }
      return [...categorySet];
    },
    searching: function(val) {
      this.currentPage = 1;

      this.search = val;
    },

    paginate (posts) {
      let to = (this.currentPage * this.cardsPerPage);

      return posts.slice(0, to);
    },

    scroll() {
      window.onscroll = () => {
        let bottomwindow =
            document.documentElement.scrollTop + window.innerHeight ===
            document.documentElement.offsetHeight;

        if (bottomwindow) {
          this.currentPage += 1;

          this.cardsDisplayed;
        }
      };
    },

    getEnvironment: function(val) {
      this.environment = val;

      this.addCards();
    },
    addCards() {
      try {
        this.cards = require(`@/content/${this.environment}/db.json`);
      } catch (e) {
        this.cards = []
      }
    }
  },
  computed: {
    partition: function() {
      return this.available('partitions').sort((a, b) => a < b ? -1 : 1);
    },
    cardsResult: function() {
      let search = this.search,
          order = this.productOrder,
          partitions = this.checked.partition,
          results = this.cards,
          intersect = function(arr1, arr2) {
            return arr1.filter(function(n) {
              return arr2.indexOf(n) !== -1;
            });
          };

      if (search) {
        results = results.filter(function(card) {
          return Object.keys(card).some(function(key) {
            return String(card[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }

      if (partitions.length) {
        results = results.filter(card => intersect(partitions, card.partitions).length)
      }

      if (order) {
        results = results.sort((p1,p2) => {
          let modifier = 1;

          if(order === 'desc') modifier = -1;

          if(p1.created < p2.created) return -1 * modifier;
          if(p1.created > p2.created) return 1 * modifier;

          return 0;
        });
      }

      return results;
    },

    cardsDisplayed () {
      return this.paginate(this.cardsResult);
    }
  },
  mounted() {
    this.addCards();
    this.scroll();
  }
}
</script>

<style scoped>
</style>